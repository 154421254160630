/* do not increment number inpur on scroll */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-autocomplete-end-adornment > .MuiAutocomplete-endAdornment{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  top: 3px
}

@media print {
  @page {
    size: A4;
    margin: 5mm;
  }

  body {
    print-color-adjust: exact;
  }

  .print-container {
    width: 210mm;
    height: auto; /* Adjust height to auto for content to expand */
    margin: auto;
    overflow: visible !important; /* Ensure no scrollbar appears */
  }
}